.keysys-form {
  legend {
    display: block;
    // width: 25%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .elements-array {
    :last-child {
      :last-child {
        *:not(:first-child):not(:last-child) input {
          border-top-right-radius: unset;
          border-bottom-right-radius: unset;
          border-left: unset !important;
          border-top-left-radius: unset !important;
          border-bottom-left-radius: unset !important;
        }
        .remove-button {
          button {
            display: block !important;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
            border-left: unset !important;
            border-top-left-radius: unset !important;
            border-bottom-left-radius: unset !important;
          }
        }
        .add-button {
          button {
            display: block !important;
          }
        }
      }
    }
  }

  .grouped-elements {
    display: flex;
    align-items: flex-end;
    .field-wrapper {
      display: inline-block;
      // width: 25%;
    }
    :first-child input,
    :first-child .form-control {
      // border-right: unset !important;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
    }
    *:not(:first-child):not(:last-child) input {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-left: unset !important;
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }
    .remove-button button {
      border-left: unset !important;
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }
    // :last-child button,
    // :last-child input {
    //   display: none;
    //   border-left: unset !important;
    //   border-top-left-radius: unset !important;
    //   border-bottom-left-radius: unset !important;
    //   border-top-right-radius: 0.25rem !important;
    //   border-bottom-right-radius: 0.25rem !important;
    // }

    :last-child .report-attribute-add {
      display: none;
      border-left: unset !important;
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
}
