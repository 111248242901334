.span-arrow::after {
  content: "";
  border: 20px solid transparent;
  position: absolute;
  border-right: 20px solid #eedbc7;
  transform: rotate(180deg);
}

.progress {
  height: 40px;
  border-radius: 0;
  margin-bottom: 10px;

  .arrow {
    background: #fff6ed;
  }

  .arrow::after {
    content: attr(data-after-content);
    border: 20px solid #fff6ed;
    border-left: 20px solid #eedbc7;
    color: var(--body-color);
  }

  .progress-bar {
    font-size: 1rem;
    background-color: #fff6ed;
    color: var(--body-color);
  }
}

@media (max-width: 768px) {
  .span-arrow::after {
    content: "";
    border: 15px solid transparent;
    position: absolute;
    border-right: 15px solid #eedbc7;
    transform: rotate(180deg);
  }

  .progress {
    height: 30px;

    .arrow::after {
      content: attr(data-after-content);
      border: 15px solid #fff6ed;
      border-left: 15px solid #eedbc7;
      color: var(--body-color);
    }

    .progress-bar {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 450px) {
  .span-arrow::after {
    content: "";
    border: 10px solid transparent;
    position: absolute;
    border-right: 10px solid #eedbc7;
    transform: rotate(180deg);
  }

  .progress {
    height: 20px;

    .arrow::after {
      content: attr(data-after-content);
      border: 10px solid #fff6ed;
      border-left: 10px solid #eedbc7;
      color: var(--body-color);
    }

    .progress-bar {
      font-size: 0.5rem;
    }
  }
}
