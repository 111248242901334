.sidebar-root-under {
  position: fixed !important;
  z-index: 0;
  width: 40px;
}

.sidebar-root-group-expanded {
  position: fixed !important;
  z-index: 9999;
  width: 22.5vw;
}

.sidebar-root-above {
  position: fixed !important;
  z-index: 9999;
}

/**************************
*      Menu Content       *
**************************/
.sidebar-menu {
  display: flex;
  height: calc(var(--vh, 1vh) * 100) !important;
  background-color: #086433;
  width: 22.5vw;
  z-index: 3 !important;
  .document-exchange-count-badge {
    background-color: #204ea0 !important;
  }
  .icon-container {
    &.expanded {
      width: 35px;
    }
  }
  // opacity: 0.9;
  .menu-item-list {
    list-style-type: none;
    margin: 20% auto 0 auto;
    font-size: 1rem;
    padding-inline-start: 0;
    .group-item-list {
      list-style-type: none;
      font-size: 1rem;
      padding-inline-start: 0;
      margin: 0 auto;
      li {
        padding: 10px 0 10px 20%;
        display: flex;
        align-items: center;
        &.current-page {
          background-color: #62b2b8;
        }
      }
    }
  }
  .menu-item-list-bottom {
    margin-top: 0;
  }
  .fas.fa-layer-group {
    color: #d4e4e6;
    display: inline;
  }
  .far.fa-flag {
    color: #d4e4e6;
    display: inline;
  }
  .fas.fa-home {
    color: #d4e4e6;
    display: inline;
  }
  .fa-envelope {
    color: #d4e4e6;
    display: inline;
  }
  .far.fa-user-circle {
    color: #d4e4e6;
    display: inline;
  }
  .no-underline {
    text-decoration-line: none;
  }
  .fas.fa-plus-circle {
    display: inline;
    color: #d4e4e6;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    font-size: 1.5rem;
  }
  .fas.fa-plus-circle:hover {
    cursor: pointer;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  .sidebar-image-icon {
    width: 2rem;
    height: auto;
  }
  .menu-item-with-plus-icon {
    color: #d4e4e6;
    margin: 0 10px;
    width: 100%;
    display: inline-block;
    font-size: 1.25rem;
    transition: 0.4s ease-in-out;
  }
  .menu-item-with-plus-icon:hover {
    color: #d4e4e6;
    text-decoration: none;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  .menu-item-without-plus-icon {
    width: 60%;
    color: #d4e4e6;
    margin: 0 10%;
    display: inline-block;
    font-size: 1.25rem;
    transition: 0.4s ease-in-out;
    text-decoration: none !important;
  }
  .menu-item-without-plus-icon:hover {
    width: 60%;
    color: #d4e4e6;
    text-decoration: none !important;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
  }
  .menu-item-list > li {
    padding: 5% 10%;
    display: flex;
    align-items: center;
  }
  .menu-item-list > .current-page {
    background-color: #62b2b8;
  }
  .sidebar-menu-arrow-button {
    display: flex;
    position: absolute;
    bottom: 80px;
    right: 5px;
    justify-content: center;
    align-content: center;
    background-color: #086433;
    border: none;
    transition: 0.4s ease-in-out;
  }
  .sidebar-menu-logout-button {
    color: #d4e4e6;
    display: flex;
    position: absolute;
    bottom: 80px;
    left: 10px;
    justify-content: center;
    align-items: center;
    background-color: #086433;
    border: none;
    transition: 0.4s ease-in-out;
    font-size: 1.25rem;
    .logout-icon {
      padding-right: 10px;
    }
  }
  .sidebar-menu-logout-button:hover {
    opacity: 0.7;
    transition: 0.4s ease-in-out;
  }
  .sidebar-menu-logout-button:active {
    color: #d4e4e6 !important;
    background-color: #086433 !important;
    border: none !important;
  }
  .sidebar-menu-logout-button:focus {
    box-shadow: none !important;
  }
  .sidebar-menu-arrow-button:hover {
    background-color: #086433;
    transition: 0.4s ease-in-out;
    opacity: 0.7;
  }
  [alt="Encircled arrow facing left"] {
    width: 2rem;
    height: auto;
  }
  .sidebar-menu-logo-button {
    color: #ced4da73;
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 10px;
    justify-content: center;
    align-items: center;
    background-color: #086433;
    border: none;
    transition: 0.4s ease-in-out;
    font-size: 0.75rem;
  }
  .sidebar-logo-border {
    border-bottom: 1px solid #ced4da73;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    position: absolute;
    width: 98%;
    border-style: solid;
    bottom: 63px;
    left: 4px;
  }
  .sidebar-logo-align {
    margin-bottom: 0rem;
    text-align: center;
    margin-right: 25px;
  }
}

@media (min-width: 1140px) and (max-width: 1600px) {
  .sidebar-menu {
    .menu-item-list {
      margin: 15% auto 0 auto !important;
    }
    .menu-item-list > li {
      padding: 2% 10% !important;
    }
    .menu-item-with-plus-icon {
      margin: 5px 10px !important;
      font-size: 1rem !important;
    }
    .menu-item-without-plus-icon {
      font-size: 1rem !important;
      margin: 5% auto 5% auto !important;
    }
    .plus-icon-background {
      height: 24px;
      width: 24px;
      .fa-plus-circle {
        font-size: 1rem !important;
      }
    }
  }
}

@media (width: 1024px) {
  .sidebar-menu {
    width: 33.33vw !important;
  }
}

@media (max-width: 992px) {
  .sidebar-menu {
    width: 100vw !important;
  }
}

@media (width: 768px) {
  .sidebar-menu {
    width: 50vw !important;
  }
}

@media (max-height: 450px) {
  .sidebar-menu {
    width: 33.33vw !important;
    .menu-item-list {
      margin: 0 auto !important;
    }
    .menu-item-list > li {
      margin: 0 10% !important;
      padding: 1% 1% !important;
    }
    .menu-item-with-plus-icon {
      margin: 5px 10px !important;
      font-size: 0.75rem !important;
    }
    .menu-item-without-plus-icon {
      font-size: 0.75rem !important;
      margin: 5% auto 5% auto !important;
    }
    .plus-icon-background {
      height: 16px;
      width: 16px;
      .fa-plus-circle {
        font-size: 0.75rem !important;
      }
    }
    .sidebar-menu-logout-button {
      font-size: 0.75rem !important;
      .logout-icon {
        width: 1.5rem;
        height: auto;
      }
    }
    [alt="Encircled arrow facing left"] {
      width: 1rem !important;
      height: auto !important;
    }
  }
}
.menu-item-list-bottom {
  margin-top: 0;
}

@media (min-width: 992px) {
  .menu-item-list-bottom {
    position: absolute;
    bottom: 125px;
    width: 100%;
  }
}
/**************************
*      Dock Content       *
**************************/

.sidebar-dock {
  width: 100%;
  overflow-y: unset !important;
  .dock-container {
    position: absolute;
    height: calc(var(--vh, 1vh) * 100) !important;
    width: 40px;
    border-right: 1px solid #eceef8;
    background-color: #086433;
    .collapsed-content {
      position: absolute;
      top: 75px;
      right: 50%;
      width: 100%;
      height: 100vh;
      transform: translateX(50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      ul {
        list-style-type: none;
        margin: 20% auto 20% auto;
        font-size: 1rem;
        padding-inline-start: 0;
        li {
          padding: 10px 0;
          margin: 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &.current-page {
            background-color: #62b2b8;
          }
        }
      }
      .floating-group-item-list {
        list-style-type: none;
        font-size: 1rem;
        padding-inline-start: 0;
        background-color: #086433;
        width: 22.5vw;
        .menu-item-with-plus-icon {
          color: #d4e4e6;
          margin: 0 10px;
          width: 100%;
          display: inline-block;
          font-size: 1.25rem;
          transition: 0.4s ease-in-out;
        }
        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;
          &.current-page {
            background-color: #62b2b8;
          }
        }
      }
    }
    .sidebar-dock-hamburger-button {
      position: absolute;
      top: 25px;
      right: 50%;
      transform: translateX(50%);
      width: 20px;
      height: auto;
      background-color: #086433;
      display: flex;
      justify-content: center;
      align-content: center;
      border: none;
      transition: 0.4s ease-in-out;
      z-index: 2;
    }
    .sidebar-dock-hamburger-button:hover {
      background-color: #086433;
      transition: 0.4s ease-in-out;
      opacity: 0.7;
    }
    .sidebar-dock-hamburger-button:active {
      background-color: #ffffff !important;
      border: none !important;
    }
    .sidebar-dock-hamburger-button:focus {
      background-color: #ffffff !important;
      border: none !important;
      box-shadow: none !important;
    }
    .fas.fa-bars {
      font-size: 24px;
    }
    .sidebar-dock-arrow-button {
      position: absolute;
      bottom: 15px;
      right: 50%;
      transform: translateX(50%);
      width: 20px;
      height: auto;
      background-color: #086433;
      display: flex;
      justify-content: center;
      align-content: center;
      border: none;
      z-index: 2;
    }
    .sidebar-dock-arrow-button:hover {
      background-color: #086433;
    }
    .sidebar-dock-arrow-button:active {
      background-color: #086433 !important;
      border: none !important;
    }
    .sidebar-dock-arrow-button:focus {
      background-color: #086433 !important;
      border: none !important;
      box-shadow: none !important;
    }
    [alt="Encircled arrow facing right"] {
      transition: 0.4s ease-in-out;
    }
    [alt="Encircled arrow facing right"]:hover {
      filter: contrast(50%);
      transition: 0.4s ease-in-out;
    }
  }
}

.submenu-overlay {
  position: fixed;
  z-index: 1;
  inset: 0px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
}

@media (max-width: 1300px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 25vw !important;
  }
}

@media (max-width: 1175px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 28vw !important;
  }
}

@media (max-width: 1024px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 30vw !important;
  }
}

@media (max-width: 980px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 33vw !important;
  }
}

@media (max-width: 890px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 36vw !important;
  }
}

@media (max-width: 800px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 39vw !important;
  }
}

@media (max-width: 767px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 50vw !important;
  }
}

@media (max-width: 600px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 53vw !important;
  }
}

@media (max-width: 550px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 60vw !important;
  }
}

@media (max-width: 475px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 75vw !important;
  }
}

@media (max-width: 425px) {
  .sidebar-root-group-expanded,
  .floating-group-item-list {
    width: 100vw !important;
  }
}
