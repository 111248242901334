.grid-screen-breadcrumb-container {
  margin-left: -15px;
  height: 25px;
  width: calc(100% + 30px);
  position: fixed;
  top: 85px;
  z-index: 1;
}

.details-screen-breadcrumb-container {
  height: 25px;
  width: 100%;
  position: fixed;
  top: 85px;
  z-index: 1;
}

ol.breadcrumb {
  background-color: #7dbdc4;
  padding: 0 0 0 16px;
  border-radius: unset;
}

nav > ol > .breadcrumb-item {
  color: white !important;
}

nav > ol > .breadcrumb-item.active {
  color: white;
}

nav > ol > .breadcrumb-item.active:before {
  color: white;
}

nav > ol > .breadcrumb-item:before {
  color: white !important;
}

.breadcrumb-item > a {
  color: #ffffff;
}

.breadcrumb-item > a:hover {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 450px) {
  nav > ol > .breadcrumb-item {
    font-size: 3vw;
  }

  nav > ol > .breadcrumb-item.active {
    font-size: 3vw;
  }

  // in the event that an Opportunity Name is greater than 50 characters
  #shrinkBreadcrumb {
    font-size: 0.5rem;

    .breadcrumb-item {
      font-size: inherit;
    }
  }
}
