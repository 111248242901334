.value,
.title {
  align-items: center;
  justify-content: center;
  display: flex;
}

.value {
  height: 60px;
  padding: 8px;
  background-color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.title {
  height: 25px;
  padding: 4px;
  background-color: #7dbdc4;
  color: white;
}

.border {
  border: 2px solid #eceef8;
}
