.modal-header {
  background-color: #204ea0;
  color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
}

.fixed-height-body.modal-body {
  // height: calc(var(--vh, 1vh) * 70);

  //Overriding react-select selected value style
  .css-1uccc91-singleValue {
    color: var(--body-color) !important;
    font-weight: 400 !important;
  }
}

.fixed-height-body {
  overflow: auto;
  // height: 80vh;
}

.modal-content {
  border: none;
}

.fade.modal.show {
  padding: 0 !important;
}

div[role="toolbar"] > .btn-danger {
  margin-right: auto;
}

div[role="toolbar"] > .btn-primary {
  margin-left: auto;
}

.modal-dialog .checkbox-round {
  margin-right: 10px;
  position: relative;
}

.modal-dialog .checkbox-round::after {
  left: 1px;
  width: 6px;
}

label[for="benchmarked"] {
  margin-bottom: 0;
}

.text-area {
  min-height: 62px;
}

.fade.modal-backdrop.show {
  z-index: 1050 !important;
}

.modal-static {
  .modal-dialog {
    transform: none !important;
  }
}

.no-options-message {
  color: hsl(0, 0%, 50%);
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 8px 12px;
}

#contained-modal-title-vcenter {
  color: #ffffff;
}
