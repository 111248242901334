#keysys-footer {
  margin-left: 40px;
  padding: 1rem 1rem;
  margin-top: 10px;
  border-top: 1px solid #babfc7;
  position: absolute;
  z-index: 1030;
  right: 0;
  left: 0;
  background-color: #f7f8fc;
}
