.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #7dbdc4 !important;
  border-color: #7dbdc4 !important;
}

.css-1pahdxg-control:hover {
  border-color: #7dbdc4 !important;
}

.css-1uccc91-singleValue {
  color: var(--body-color) !important;
}
