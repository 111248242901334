@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght%40300&display=swap");

$primary: #7dbdc4;
$secondary: #efebe6;
$success: #80b758;
$info: #eedbc7;
$warning: #fea620;
$danger: #f44336;
$light: #efebe6;
$dark: #5a585a;
$footer: #bdbdbd;
$body-color: #333333;
$font-family-base: "Open Sans", sans-serif;

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "login";
@import "header";
@import "sidebar";
@import "grid";
@import "modal";
@import "breadcrumb";
@import "valueWithTitleBox";
@import "keysysAccordion";
@import "progressBar";
@import "keysysSelect";
@import "footer";
@import "keysysForm";

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f7f8fc;
  font-weight: 400;
  font-family: $font-family-base !important;
}

html,
body,
#root,
.App {
  height: calc(100vh - 135px); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 135px) !important;
}

//40 pixels = sidebar width
//135 pixels = breadcrumb + header height
.content-container {
  //margin-left: 40px;
  //margin-top: 100px;
  min-height: 300px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

th {
  justify-content: center;
}

td {
  justify-content: center;
}

tr {
  justify-content: center;
  text-align: center;
}
.hidden {
  display: none;
}
.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:disabled,
.btn-danger,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger:disabled,
.btn-success,
.btn-success:focus,
.btn-success:hover,
.btn-success:disabled,
.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:disabled,
.btn-info,
.btn-info:focus,
.btn-info:hover,
.btn-info:disabled {
  color: #fff;
}

.import-dropdown {
  color: white;
  background-color: white;
  // display: flex;
  margin-bottom: 15px;
  align-self: right;
  text-align: right;
  border: 1px solid #079046;
  color: #079046;
  margin-left: 10px;
}

.import-button {
  background-color: #079046;
  color: white;
  margin-bottom: 15px;
  align-self: right;
  text-align: right;
  margin-left: 10px;
}

.finosec-button {
  display: flex;
  color: white;
  background-image: linear-gradient(120deg, #72bec5 0%, #a8d7db 100%);
  border: 1px solid #72bec5;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.finosec-button:hover {
  color: white;
  opacity: 0.7;
  border: 1px solid #72bec5;
  transition: all 0.4s ease-in-out;
}

.finosec-button-info {
  background-color: #0e63ac;
}

.customer-report-list-header .finosec-button-info {
  background-color: #0e63ac;
  color: white;
  border: 1px solid #72bec5;
}

.customer-report-list-header {
  position: sticky;
}

.finosec-button-info:hover,
.finosec-button-info:focus {
  color: white;
  opacity: 0.7;
  background-color: #0e63ac;
  border: 1px solid #72bec5;
  transition: all 0.4s ease-in-out;
}

.finosec-button-info:not(:disabled):not(.disabled):active,
.finosec-button-info:not(:disabled):not(.disabled).active,
.show > .finosec-button-info.dropdown-toggle {
  color: #fff;
  background-color: #0e63ac;
  border-color: #51a7b0;
}

.finosec-form-label {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: bold;
  color: #383632;
}

.plus-icon-background {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #62b2b8;
  display: flex;
  justify-content: center;
  align-items: center;
}

table thead {
  background-color: #cccccc !important;
  color: black;
  border-bottom-width: -1px;
}

table tbody td {
  border-bottom-width: 0px !important;
}

table tbody tr {
  background-color: transparent !important;
}

table {
  border: 0px !important;
  background-color: transparent !important;
}

#graphicUAR {
  margin: 0 auto;
}

.customer-report-list-header button {
  background-color: #cccccc;
  color: #000000;
  border-color: #bdbdbd;
}

.customer-report-list-header button:hover {
  background-color: transparent;
  opacity: 0.7;
  color: #000000;
  border-color: #bdbdbd;
}

.title-bar {
  background-color: #079247;
  color: #ffffff;
  font-style: italic;
}

#customerImportForm > div {
  width: 100%;
}

#customerImportForm label {
  display: none;
}

.tr-user-access-reports-summary-left {
  width: 55%;
  text-align: right;
}

.tr-user-access-reports-summary-right {
  text-align: left;
  padding-left: 25px !important;
}

.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.clear {
  clear: both;
}
.form-check-input:checked {
  background-color: #0e63ac;
  border-color: #0e63ac;
}

.padding-12 {
  padding: 0 12px;
  margin: 0 12px;
}
.padding-0 {
  padding: 0 12px;
  margin: 0 0;
}
.margin-0 {
  padding: 0;
  margin: 0;
}
