.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.count {
  border: 2px solid #ffffff;
  border-radius: 20px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 3px 7px;
  margin-left: 10px;
}
h2 {
  font-size: larger;
  font-weight: bold;
}
h3 {
  font-size: larger;
  font-weight: bold;
}
.accordion-header {
  display: flex;
  align-items: center;
  background-color: #0e63ac;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;

  .import-accordion-button {
    color: white;
    // margin-bottom: 15px;
    // align-self: right;
    // text-align: right;
    margin-left: 10px;
  }

  .import-grid-button {
    color: white;
    // margin-bottom: 15px;
    align-self: right;
    text-align: right;
    margin-left: 12px;
  }

  span[data-testid="accordion-title"] {
    cursor: pointer;
  }
  button {
    background-color: #0e63ac !important;
    color: white !important;
  }
  .accordion-button::after {
    filter: brightness(0) invert(1);
  }
  .accordion-button:focus {
    border-color: #0e63ac !important;
    box-shadow: 0 0 0 0.25rem #0e63ac;
  }
  .accordion-button > .container {
    margin-left: unset;
  }
}

.fa-chevron-down,
.fa-chevron-up {
  color: black;
}
.nested-accordion {
  .accordion-header button {
    background-color: #eee !important;
    color: #222222 !important;
  }
  .accordion-button::after {
    filter: brightness(1) invert(1);
  }
}
.header-end {
  text-align: right;
  display: flex;

  .help-text-link {
    margin-right: 10px;
  }

  .fa-plus-circle,
  .fa-question-circle {
    font-size: 1.5em;
    color: #ffffff;
  }
}

.fa-question-circle {
  color: #62b2b8;
}

.fa-plus-circle {
  cursor: pointer;
}
dl {
  margin: 0;
  dt,
  dd {
    display: inline;
  }
}

/* Cards */

.card-header.accordion-card {
  padding: 0 !important;
  background: none !important;
  border: 0;
  margin-bottom: 0;
}

.card-header .row .col {
  margin-bottom: 0;
}
.accordion-collapse .accordion-card button {
  @extend .accordion-button;
  box-shadow: none !important;
  transform: rotate(-180deg);
  border: none;
  width: 60px;
  margin-left: 50px;
}

.status-pill {
  border-radius: 25px;
  color: #eee;
  padding: 5px 10px;
  white-space: nowrap;

  &.status-readyforintake {
    background-color: #2196f3;
  }
  &.status-processing {
    background-color: #4caf50;
  }
  &.status-rejected {
    background-color: #f50057;
  }
}

dl {
  display: block;
  float: left;
}
.row {
  max-width: 100%;
}
