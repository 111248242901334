nav.navbar {
  height: 85px;
  margin-left: 40px;
  position: fixed;
  width: calc(100% - 40px);
  z-index: 2;
  top: 0;
  border-bottom: 2px solid #086433;
  background-color: #f7f8fc;
}

.iFrameContainer {
  height: 35px;
  position: relative;
}

nav.navbarIFrame {
  height: 35px;
  margin-left: 0px;
  position: fixed;
  width: calc(100%);
  z-index: 2;
  top: 0;
  border-bottom: 2px solid #0e63ac;
  background-color: white;
}

.add-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0;
}

.nav-link {
  border-bottom: 0 !important;
}

.navbar-text {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 1000 !important;
  text-align: center;
}

.header-dropdown {
  margin-right: 10px;
}

#headerButton {
  font-size: 16px;
  margin-right: 0;
}

#shadow {
  background-color: transparent;
  position: fixed;
  width: calc(100% - 40px);
  left: 40px;
  top: 85px;
  z-index: 1;
}

#shrunkenShadow {
  height: unset;
  font-size: 1rem;
  background-color: transparent;
  position: fixed;
  width: calc(100% - 40px);
  left: 40px;
  top: 85px;
  z-index: 1;
}

#shrinkTitle50 {
  font-size: 24px !important;
}

#shrinkTitle75 {
  font-size: 20px !important;
}

@media (max-width: 992px) {
  .navbar-text {
    display: block;
  }

  #shrinkTitle50 {
    font-size: 16px !important;
  }

  #shrinkTitle75 {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  nav.navbar .dropdown-column {
    padding: 0;
  }

  .header-dropdown {
    margin-right: 0;
    margin-top: 2px;
  }
}

@media (max-width: 450px) {
  .header-dropdown {
    font-size: 14px;
  }

  #shrinkTitle50 {
    font-size: 14px !important;
  }

  #shrinkTitle75 {
    font-size: 12px !important;
  }

  #shadow {
    height: unset;
    font-size: 3vw;
  }

  #shrunkenShadow {
    height: unset;
    font-size: 0.5rem;
    background-color: transparent;
    position: fixed;
    width: calc(100% - 40px);
    left: 40px;
    top: 85px;
    z-index: 1;
  }
}
